<template>
  <v-container fill-height>
    <validation-observer
      ref="getStartedForm"
      v-slot="{ invalid }"
      slim
    >
      <v-row align="center" justify="center" no-gutters>
        <v-card
          class="elevation-3 pa-3 br-5 pa-lg-4"
          shaped
          max-width="500"
          width="100%"
        >
          <v-card-title>
            Get Started
          </v-card-title>
          <v-card-text>
            <validation-provider
              v-slot="{ errors }"
              rules="required|min:3"
              name="Name"
            >
              <v-text-field
                v-model="form.displayName"
                label="Name*"
                hint="Your display name."
                type="text"
                outlined
                :error-messages="errors"
                autofocus
                required
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              rules="required|min:3"
              name="Organization Name"
            >
              <v-text-field
                v-model="form.organizationName"
                label="Organization Name"
                hint="Your company or business name"
                type="text"
                outlined
                :error-messages="errors"
                required
              />
            </validation-provider>
          </v-card-text>
          <v-card-actions>
            <v-btn
              block
              x-large
              color="primary"
              :disabled="invalid"
              :loading="loading"
              @click="next"
            >
              Start With Free Trial
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
    </validation-observer>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'GetStarted',
  data: () => ({
    loading: false,
    form: {
      displayName: '',
      organizationName: ''
    }
  }),
  computed: {
    ...mapGetters(['user', 'organization'])
  },
  watch: {
    'organization.freeTrialStartAt': {
      handler(date) {
        date && this.$router.push({ name: 'index' })
      },
      immediate: true
    }
  },
  mounted() {
    this.form.displayName = this.user.displayName
    if (this.organization) {
      this.form.organizationName = this.organization.name
    }
  },
  methods: {
    async next() {
      this.loading = true
      const { displayName, organizationName } = this.form
      const user = await this.$getAsyncCurrentUser()
      await user.updateProfile({ displayName })
      const now = this.$nowstamp()
      let organizationId
      if (!this.organization) {
        const memberUserIds = [user.uid]
        await this.$db.collection('user-settings').doc(user.uid).set({ newsletter: true })
        const organization = await this.$db.collection('organizations').add({
          name: organizationName,
          memberUserIds,
          createdBy: user.uid,
          freeTrialStartAt: now,
          createdAt: now,
          updatedAt: now
        })
        organizationId = organization.id
      } else {
        organizationId = this.organization.id
        await this.$db.collection('organizations').doc(organizationId).update({
          name: this.form.organizationName,
          updatedAt: now,
          freeTrialStartAt: now
        })
      }
      this.loading = false
    }
  }
}
</script>
